import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { Main } from '@/modules/main/components/templates/main';

import { RootState } from '../config/redux';

type Props = RouteProps & {
  component: React.FC;
  isPrivate?: boolean;
};

export const RouteWrapper: React.FC<Props> = ({
  component: Component,
  isPrivate,
  ...rest
}: Props) => {
  const { token } = useSelector((state: RootState) => state.auth);

  if (!token && isPrivate) {
    return <Redirect to="/" />;
  }

  if (token && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }
  const Layout = Main;

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
