import React from 'react';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from '../config/redux';

type Props = {
  children: React.ReactNode;
};

const ReduxProvider: React.FC<Props> = ({ children }: Props) => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>{children}</PersistGate>
  </Provider>
);

export { ReduxProvider };
