import { takeLatest, call, all, put } from 'redux-saga/effects';

import { RootState } from '@/main/config/redux';
import { ISignIn } from '@/modules/sign-in/use-cases/contracts/sign-in';

import { makeSignInQlikUseCase } from '../factories/make-sign-in-qlik-use-case';
import { makeSignInUseCase } from '../factories/make-sign-in-use-case';
import { AuthModel } from '../models/auth';
import { AuthQlikModel } from '../models/auth-qlik';
import { ISignInQlik } from '../use-cases/contracts/sign-in-qlik';
import { setCurrentAuth, signOut } from './actions';
import { ActionTypes } from './types';

type State = {
  payload: RootState;
};

const checkSignInQlik = async () => {
  const signInQlikUseCase: ISignInQlik = makeSignInQlikUseCase();

  const data: AuthQlikModel | undefined = await signInQlikUseCase.execute();

  return data;
};

const renewJWTToken = async (idQlik: string) => {
  const signInUseCase: ISignIn = makeSignInUseCase();

  const data: AuthModel | undefined = await signInUseCase.execute({
    idQlik,
  });

  return data;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function* checkToken({ payload }: State) {
  const data: AuthQlikModel | undefined = yield call(checkSignInQlik);

  try {
    if (!data) {
      yield put(signOut());
    } else {
      const response: AuthModel | undefined = yield call(
        renewJWTToken,
        data?.id,
      );

      if (response?.error) {
        console.error(response.error);
        //redirecionar para uma pagina de error
        return;
      } else {
        if (response) {
          yield put(setCurrentAuth(response));
        }
      }
    }
  } catch (error) {
    yield put(signOut());
  }
}

export default all([
  // @ts-ignore
  takeLatest(ActionTypes.REDUX_PERSIST, checkToken),
]);
