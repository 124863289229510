import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import { AlertDialog } from '@/shared/components/atoms/alert-dialog';

import { makeDeleteGroupUseCase } from '../../factories/make-delete-group-use-case';
import { closeDialog, removeGroup } from '../../state/actions';

type Props = {
  currentGroupId: string;
  isOpenDialog: boolean;
};

const DeleteGroup: React.FC<Props> = ({
  currentGroupId,
  isOpenDialog,
}: Props) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCancel = useCallback(() => {
    dispatch(closeDialog());
  }, [dispatch]);

  const onConfirm = useCallback(async () => {
    try {
      setIsLoading(true);
      const deleteGroupUseCase = makeDeleteGroupUseCase();
      await deleteGroupUseCase.execute({ id: currentGroupId });
      dispatch(removeGroup(currentGroupId));
    } catch (error) {
      toast({
        title: 'Ocorreu um erro ao tentar apagar esse grupo.',
        description: (error as Error).message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, toast, currentGroupId]);

  return (
    <AlertDialog
      cancelButtonText="NÃO"
      confirmButtonText="SIM"
      isLoading={isLoading}
      isOpen={isOpenDialog}
      message="Deseja realmente excluir esse grupo?"
      header="Excluir grupo"
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

export { DeleteGroup };
