import produce from 'immer';
import { Reducer } from 'redux';

import { VisionState, ActionTypes } from './types';

const INITIAL_STATE: VisionState = {
  currentVision: {
    id: '',
    name: '',
    slug: '',
    layout: undefined,
  },
  visions: [],
  isOpenModal: false,
  isOpenModalShare: false,
};

const vision: Reducer<VisionState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SET_CURRENT_VISION: {
        draft.currentVision = action.payload.data;
        break;
      }
      case ActionTypes.SET_VISIONS: {
        draft.visions = action.payload.data;
        draft.currentVision = action.payload.data[0];
        break;
      }
      case ActionTypes.SET_NEW_VISION: {
        draft.visions = [...draft.visions, action.payload.data];
        draft.isOpenModal = false;
        draft.currentVision = action.payload.data;
        break;
      }
      case ActionTypes.UPDATE_LAYOUT: {
        draft.currentVision.layout = action.payload.layout;
        break;
      }
      case ActionTypes.OPEN_MODAL: {
        draft.isOpenModal = true;
        break;
      }
      case ActionTypes.CLOSE_MODAL: {
        draft.isOpenModal = false;
        break;
      }
      case ActionTypes.OPEN_MODAL_SHARE: {
        draft.isOpenModalShare = true;
        break;
      }
      case ActionTypes.CLOSE_MODAL_SHARE: {
        draft.isOpenModalShare = false;
        break;
      }
      default:
    }
  });
};

export default vision;
