import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IGetVision } from '../use-cases/contracts/get-vision';
import { GetVisionUseCase } from '../use-cases/get-vision';

export const makeGetVisionUseCase = (visionId: string): IGetVision =>
  new GetVisionUseCase(
    makeApiUrl(`/vision/${visionId}`),
    makeAuthorizeHttpClientDecorator(),
  );
