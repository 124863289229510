import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IEditGroup } from '../use-cases/contracts/edit-group';
import { EditGroupUseCase } from '../use-cases/edit-group';

export const makeEditGroupUseCase = (): IEditGroup =>
  new EditGroupUseCase(
    makeApiUrl('/group'),
    makeAuthorizeHttpClientDecorator(),
  );
