import { ISignInQlik } from './contracts/sign-in-qlik';

export class SignInQlikUseCase implements ISignInQlik {
  constructor(
    private readonly url: string,
    private readonly QLIK_INTEGRATION_ID: string,
  ) {}

  async execute(): Promise<ISignInQlik.Result> {
    const httpResponse = await fetch(this.url, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'qlik-web-integration-id': this.QLIK_INTEGRATION_ID,
      },
    });

    if (httpResponse.status === 200) {
      const data = await httpResponse.json();
      return data;
    }

    return undefined;
  }
}
