import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnauthorizedError } from '@/shared/presentation/errors/unauthorized-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IEditGroup } from './contracts/edit-group';

export class EditGroupUseCase implements IEditGroup {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IEditGroup.Result>,
  ) {}

  async execute(params: IEditGroup.Params): Promise<IEditGroup.Result> {
    const { id, name, spaceId } = params;
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'put',
      body: { name, spaceId },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.unauthorized:
        throw new UnauthorizedError();
      case HttpStatusCode.forbidden:
        throw new ApplicationError(httpResponse?.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
