import React from 'react';
import ReactDOM from 'react-dom';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import 'devextreme/dist/css/dx.light.css';
import './main/config/reactotron';

import Router from '@/main/routes/router';

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root'),
);
