import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IGetPanelsByVisionId } from '../use-cases/contracts/get-panels-by-vision-id';
import { GetPanelsByVisionIdUseCase } from '../use-cases/get-panels-by-vision-id';

export const makeGetPanelsByVisionIdUseCase = (
  visionId: string,
): IGetPanelsByVisionId =>
  new GetPanelsByVisionIdUseCase(
    makeApiUrl(`/panel/${visionId}`),
    makeAuthorizeHttpClientDecorator(),
  );
