import React from 'react';

import { Text, TextProps } from '@chakra-ui/layout';

const TextError: React.FC<TextProps> = ({ children }: TextProps) => {
  return (
    <Text fontSize="20px" color="red.500">
      {children}
    </Text>
  );
};

export { TextError };
