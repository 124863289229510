import { takeLatest, call, all } from 'redux-saga/effects';

import { makeCreatePanelsUseCase } from '../factories/make-create-panels-use-case';
import { makeDeletePanelByIdUseCase } from '../factories/make-delete-panel-by-id-use-case';
import { PanelModel } from '../models/panel';
import { ICreatePanels } from '../use-cases/contracts/create-panels';
import { IDeletePanelById } from '../use-cases/contracts/delete-panel-by-ids';
import { ActionTypes } from './types';

type PayloadRemovePanel = {
  payload: {
    id: string;
  };
};

const removePanelDatabase = async (id: string) => {
  const deletePanelByIDUseCase: IDeletePanelById =
    makeDeletePanelByIdUseCase(id);

  await deletePanelByIDUseCase.execute();
};

export function* removePanel({ payload }: PayloadRemovePanel) {
  yield call(removePanelDatabase, payload.id);
}

export default all([
  // @ts-ignore
  takeLatest(ActionTypes.REMOVE_PANEL, removePanel),
  // @ts-ignore
  //takeLatest(ActionTypes.SET_SELECTED_PANELS, setSelectedPanels),
]);
