import React from 'react';

import { SignIn } from '../presentation/components/page/sign-in';
import { makeSignInQlikUseCase } from './make-sign-in-qlik-use-case';
import { makeSignInUseCase } from './make-sign-in-use-case';

export const makeSignInPage: React.FC = () => {
  return (
    <SignIn
      signInQlikUseCase={makeSignInQlikUseCase()}
      signInUseCase={makeSignInUseCase()}
    />
  );
};
