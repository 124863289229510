import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IGetAllUsers } from '../use-cases/contracts/get-all-users';
import { GetAllUsersUseCase } from '../use-cases/get-all-users';

export const makeGetAllUserUseCase = (): IGetAllUsers =>
  new GetAllUsersUseCase(
    makeApiUrl('/user'),
    makeAuthorizeHttpClientDecorator(),
  );
