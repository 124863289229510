import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Flex } from '@chakra-ui/layout';

import { setCurrentAuth } from '@/modules/sign-in/state/actions';
import { ISignIn } from '@/modules/sign-in/use-cases/contracts/sign-in';
import { ISignInQlik } from '@/modules/sign-in/use-cases/contracts/sign-in-qlik';
import { sleep } from '@/shared/utils/sleep';

type Props = {
  signInQlikUseCase: ISignInQlik;
  signInUseCase: ISignIn;
};

const SignIn: React.FC<Props> = ({
  signInQlikUseCase,
  signInUseCase,
}: Props) => {
  const dispatch = useDispatch();

  const [idQlik, setIdQlik] = useState<string>('');

  const checkIsAuthenticatedQlik = useCallback(async (): Promise<void> => {
    try {
      const data = await signInQlikUseCase.execute();

      if (data) {
        setIdQlik(data?.id);
      } else {
        await sleep(500);
        await checkIsAuthenticatedQlik();
      }
    } catch (error) {
      await sleep(500);
      await checkIsAuthenticatedQlik();
    }
  }, [signInQlikUseCase]);

  const authenticationApplication = useCallback(
    async (id: string) => {
      const data = await signInUseCase.execute({ idQlik: id });

      if (data) {
        dispatch(setCurrentAuth(data));
      }
    },
    [signInUseCase, dispatch],
  );

  useEffect(() => {
    checkIsAuthenticatedQlik();
  }, [checkIsAuthenticatedQlik]);

  useEffect(() => {
    if (idQlik) {
      authenticationApplication(idQlik);
    }
  }, [idQlik, authenticationApplication]);

  return (
    <Flex
      as="iframe"
      height="100vh"
      width="100vw"
      src="https://athenasagricola.us.qlikcloud.com/login"
    ></Flex>
  );
};

export { SignIn };
