import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IGetAllUsersQlik } from '../use-cases/contracts/get-all-users-qlik';
import { GetAllUsersQlikUseCase } from '../use-cases/get-all-users-qlik';

export const makeGetAllUserQlikUseCase = (): IGetAllUsersQlik =>
  new GetAllUsersQlikUseCase(
    makeApiUrl('/users-qlik'),
    makeAuthorizeHttpClientDecorator(),
  );
