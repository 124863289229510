import React, { useCallback, useMemo } from 'react';
import { RiMenuLine, RiSave2Fill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import {
  Drawer,
  Flex,
  Icon,
  Button,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { DropDownBox } from '@/modules/group/components/atom/drop-down-box';
import { makeUpdateLayoutByVisionIdUseCase } from '@/modules/vision/factories/make-update-layout-by-vision-id-use-case';

import { Profile } from '../atoms/profile';
import { DrawerContent } from '../molecules/drawer-content';

const Header: React.FC = () => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { currentVision } = useSelector((state: RootState) => state.vision);
  const { panels } = useSelector((state: RootState) => state.panel);

  const updateLayoutByVisionIdUseCase = useMemo(() => {
    return makeUpdateLayoutByVisionIdUseCase(currentVision.id);
  }, [currentVision]);

  const { pathname } = useLocation();

  const handlePressSaveLayout = useCallback(async (): Promise<void> => {
    try {
      const response = await updateLayoutByVisionIdUseCase.execute({
        layout: currentVision.layout,
      });

      if (response) {
        toast({
          title: 'Successo',
          description: 'Visão salva com sucesso',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Ocorreu um erro ao tentar salvar a visão.',
        description: (error as Error).message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [currentVision.layout, updateLayoutByVisionIdUseCase, toast]);

  return (
    <>
      <Flex
        as="header"
        alignItems="center"
        justifyContent="space-between"
        bg="green.500"
        padding="10px"
      >
        <Flex align="center">
          <Button
            aria-label="Open navigation"
            variant="unstyled"
            onClick={onOpen}
            color="whiteAlpha.900"
            alignItems="center"
            justifyContent="center"
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Icon as={RiMenuLine} fontSize="30" />
          </Button>
        </Flex>

        <Flex align="center">
          {pathname === '/dashboard' ? (
            <>
              <DropDownBox />

              {panels.length > 0 && (
                <Button
                  variant="unstyled"
                  alignItems="center"
                  justifyContent="center"
                  _focus={{
                    boxShadow: 'none',
                  }}
                  ml={15}
                  onClick={handlePressSaveLayout}
                >
                  <Icon as={RiSave2Fill} fontSize="35" color="whiteAlpha.900" />
                </Button>
              )}
            </>
          ) : null}
        </Flex>

        <Flex align="center">
          <Profile />
        </Flex>
      </Flex>
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerContent onClose={onClose} />
      </Drawer>
    </>
  );
};

export { Header };
