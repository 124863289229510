import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';
import { setNewVisionInCurrentGroup } from '@/modules/group/state/actions';
import { Modal } from '@/shared/components/atoms/modal';
import { InputForm } from '@/shared/components/molecules/input-form';

import { makeCreateVisionUseCase } from '../../factories/make-create-vision-use-case';
import { closeModal } from '../../state/actions';
import { setNewVision } from '../../state/actions';

type InputProps = {
  name: string;
};

const CreateVision: React.FC = () => {
  const dispatch = useDispatch();

  const {
    vision: { isOpenModal },
    group: { currentGroup },
  } = useSelector((state: RootState) => state);

  const [formData, setFormData] = useState<InputProps>({ name: '' });
  const [textError, setTextError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePressSave = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      const createVisionUseCasew = makeCreateVisionUseCase();
      const vision = await createVisionUseCasew.execute({
        name: formData.name,
        groupId: currentGroup.id,
      });

      if (vision) {
        dispatch(setNewVision(vision));
        dispatch(setNewVisionInCurrentGroup(vision));
      }
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setFormData({ name: '' });
      setIsLoading(false);
    }
  }, [formData, dispatch, currentGroup.id]);

  const handlePressCancel = useCallback((): void => {
    setFormData({ name: '' });
    dispatch(closeModal());
  }, [dispatch]);

  useEffect(() => {
    setFormData({ name: '' });
    setIsLoading(false);
    setTextError('');
  }, []);

  return (
    <Modal
      title={`Cadastro de nova visão | ${currentGroup.name}`}
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={isOpenModal}
      isLoading={isLoading}
    >
      <InputForm
        id="new-vision"
        label="Nova visão"
        onChange={(e) => setFormData({ name: e.target.value })}
        textError={textError}
      />
    </Modal>
  );
};

export { CreateVision };
