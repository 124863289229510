import React, { useCallback, useEffect, useState } from 'react';
import { AiOutlineCaretRight } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '@chakra-ui/icon';
import { Flex, Text, VStack, Box } from '@chakra-ui/layout';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  PopoverBody,
} from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import { VisionModel } from '@/modules/vision/models/vision';
import { setCurrentVision } from '@/modules/vision/state/actions';

import { GroupModel } from '../../models/group';
import { setCurrentGroup } from '../../state/actions';

const Menu: React.FC = () => {
  const dispatch = useDispatch();
  const {
    group: { currentGroup, groups },
    vision: { currentVision },
  } = useSelector((state: RootState) => state);

  const onClickGroup = useCallback(
    (e: GroupModel): void => {
      if (e.id !== currentGroup?.id) {
        const selectedData = {
          id: e.id,
          name: e.name,
          slug: e.slug,
        };
        dispatch(setCurrentGroup(selectedData));
        dispatch(
          setCurrentVision({ id: '', name: '', layout: undefined, slug: '' }),
        );
      }
    },
    [currentGroup.id, dispatch],
  );

  const onClckVision = useCallback(
    (e: VisionModel) => {
      if (e.id !== currentVision?.id) {
        const selectedData = {
          id: e.id,
          name: e.name,
          slug: e.slug,
          layout: e.layout,
        };

        dispatch(setCurrentVision(selectedData));
      }
    },
    [currentVision, dispatch],
  );

  return (
    <VStack alignItems="flex-start" spacing="4">
      {groups.map((group) => (
        <Popover
          key={group.id}
          trigger="click"
          placement="right-start"
          variant="responsive"
        >
          <PopoverTrigger>
            <Button
              justifyContent="center"
              alignItems="flex-start"
              borderRadius="6px"
              padding="3"
              _focus={{
                boxShadow: 'none',
              }}
              backgroundColor={
                group.id === currentGroup.id ? 'green.500' : 'transparent'
              }
              color={group.id === currentGroup.id ? 'whiteAlpha.900' : 'black'}
              _hover={{
                backgroundColor: 'gray.100',
              }}
              focusBorderColor="transparent"
              onClick={() => onClickGroup(group)}
            >
              <Text color="black" fontWeight="bold" fontSize="14px">
                {group.name}
              </Text>
            </Button>
          </PopoverTrigger>

          {
            //@ts-ignore
            group?.visions?.length > 0 && (
              <PopoverContent
                border="0"
                _focus={{
                  boxShadow: 'none',
                }}
              >
                <PopoverBody boxShadow="2xl">
                  <VStack alignItems="flex-start" spacing="4">
                    {group?.visions?.map((vision) => (
                      <Button
                        key={vision.id}
                        justifyContent="center"
                        alignItems="flex-start"
                        borderRadius="6px"
                        padding="3"
                        _focus={{
                          boxShadow: 'none',
                        }}
                        _hover={{
                          backgroundColor: 'gray.100',
                        }}
                        focusBorderColor="transparent"
                        backgroundColor={
                          vision.id === currentVision.id
                            ? 'green.500'
                            : 'transparent'
                        }
                        color={
                          vision.id === currentVision.id
                            ? 'whiteAlpha.900'
                            : 'black'
                        }
                        onClick={() => onClckVision(vision)}
                      >
                        <Text color="black" fontWeight="bold" fontSize="14px">
                          {vision.name}
                        </Text>
                      </Button>
                    ))}
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            )
          }
        </Popover>
      ))}
    </VStack>
  );
};

export { Menu };
