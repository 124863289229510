import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { ISaveNewSpace } from '../use-cases/contracts/save-new-space';
import { SaveNewSpaceUseCase } from '../use-cases/save-new-space';

export const makeSaveNewSpaceUseCase = (): ISaveNewSpace =>
  new SaveNewSpaceUseCase(
    makeApiUrl('/space'),
    makeAuthorizeHttpClientDecorator(),
  );
