import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';

import { CreateEditSpace } from '../molecules/create-edit-space';
import { DeleteSpace } from '../molecules/delete-space';

const Space: React.FC = () => {
  const { currentSpace, isOpenModal, isOpenDialog, typeModal } = useSelector(
    (state: RootState) => state.space,
  );

  return (
    <>
      <CreateEditSpace
        currentSpace={currentSpace}
        isOpenModal={isOpenModal}
        typeModal={typeModal}
      />
      <DeleteSpace
        currentSpaceId={currentSpace.id}
        isOpenDialog={isOpenDialog}
      />
    </>
  );
};

export { Space };
