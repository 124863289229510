import React from 'react';

import { ThemeProvider, ColorModeScript, CSSReset } from '@chakra-ui/react';

import { theme } from '../config/theme/chakra-theme';

type Props = {
  children: React.ReactNode;
};

const ChakraProvider: React.FC<Props> = ({ children }: Props) => (
  <ThemeProvider theme={theme}>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <CSSReset />
    {children}
  </ThemeProvider>
);

export { ChakraProvider };
