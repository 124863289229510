import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnauthorizedError } from '@/shared/presentation/errors/unauthorized-error';

import { IDeleteSpace } from './contracts/delete-space';

export class DeleteSpaceUseCase implements IDeleteSpace {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IDeleteSpace.Result>,
  ) {}

  async execute(params: IDeleteSpace.Params): Promise<IDeleteSpace.Result> {
    const { id } = params;
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return;
      case HttpStatusCode.unauthorized:
        throw new UnauthorizedError();
      default:
        throw new ApplicationError(
          'Erro ao apagar o espaço. Verifica se não existe nenhum grupo associado a ele',
        );
    }
  }
}
