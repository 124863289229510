import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IRemoveAssociateUserVision } from '../use-cases/contracts/remove-associate-user-vison';
import { RemoveAssociateUserVisionUseCase } from '../use-cases/remove-associate-user-vison';

export const makeRemoveAssociateUserVisionUseCase = (
  visionId: string,
  userId: string,
): IRemoveAssociateUserVision =>
  new RemoveAssociateUserVisionUseCase(
    makeApiUrl(`/user-vision/${visionId}?userId=${userId}`),
    makeAuthorizeHttpClientDecorator(),
  );
