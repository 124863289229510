import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useToast } from '@chakra-ui/react';

import { AlertDialog } from '@/shared/components/atoms/alert-dialog';

import { makeDeleteSpaceUseCase } from '../../factories/make-delete-space-use-case';
import { closeDialog, removeSpace } from '../../state/actions';

type Props = {
  currentSpaceId: string;
  isOpenDialog: boolean;
};

const DeleteSpace: React.FC<Props> = ({
  currentSpaceId,
  isOpenDialog,
}: Props) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCancel = useCallback(() => {
    dispatch(closeDialog());
  }, [dispatch]);

  const onConfirm = useCallback(async () => {
    try {
      setIsLoading(true);
      const deleteSpaceUseCase = makeDeleteSpaceUseCase();
      await deleteSpaceUseCase.execute({ id: currentSpaceId });
      dispatch(removeSpace(currentSpaceId));
    } catch (error) {
      toast({
        title: 'Ocorreu um erro ao tentar apagar esse espaço.',
        description: (error as Error).message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, toast, currentSpaceId]);

  return (
    <AlertDialog
      cancelButtonText="NÃO"
      confirmButtonText="SIM"
      isLoading={isLoading}
      isOpen={isOpenDialog}
      message="Deseja realmente excluir esse espaço?"
      header="Excluir espaço"
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

export { DeleteSpace };
