import produce from 'immer';
import { Reducer } from 'redux';

import { AuthState, ActionTypes } from './types';

const INITIAL_STATE: AuthState = {
  token: '',
  user: {
    email: '',
    name: '',
    idQlik: '',
    isAdmin: false,
    licenseQlik: '',
  },
  error: '',
};

const auth: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.AUTH_SUCCESS: {
        draft.token = action.payload.data.token;
        draft.user = action.payload.data.user;
        draft.error = action.payload.data.error || '';
        break;
      }
      case ActionTypes.SIGN_OUT: {
        draft.token = '';
        draft.user = {
          email: '',
          name: '',
          idQlik: '',
          isAdmin: false,
          licenseQlik: '',
        };
        break;
      }
      default:
    }
  });
};

export default auth;
