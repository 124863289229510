import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IAssociateUserVision } from './contracts/associate-user-vison';

export class AssociateUserVisionUseCase implements IAssociateUserVision {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IAssociateUserVision.Result>,
  ) {}

  async execute(
    params: IAssociateUserVision.Params,
  ): Promise<IAssociateUserVision.Result> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      default:
        throw new UnexpectedError();
    }
  }
}
