import { GroupModel } from '../models/group';

export enum ActionTypes {
  SET_GROUPS = '@group/SET_GROUPS',
  SET_CURRENT_GROUP = '@group/SET_CURRENT_GROUP',
  SET_NEW_GROUP = '@group/SET_NEW_GROUP',
  REMOVE_GROUP = '@group/REMOVE_GROUP',
  EDIT_GROUP = '@group/EDIT_GROUP',

  SET_NEW_VISION = '@group/SET_NEW_VISION',

  OPEN_MODAL = '@group/OPEN_MODAL',
  CLOSE_MODAL = '@group/CLOSE_MODAL',

  OPEN_DIALOG = '@group/OPEN_DIALOG',
  CLOSE_DIALOG = '@group/CLOSE_DIALOG',
}

export type GroupState = {
  currentGroup: GroupModel;
  groups: GroupModel[];

  isOpenModal: boolean;
  isOpenDialog: boolean;

  typeModal: 'add' | 'edit';
};
