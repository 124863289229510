import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnauthorizedError } from '@/shared/presentation/errors/unauthorized-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IGetAllUsersQlik } from './contracts/get-all-users-qlik';

export class GetAllUsersQlikUseCase implements IGetAllUsersQlik {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IGetAllUsersQlik.Result>,
  ) {}

  async execute(): Promise<IGetAllUsersQlik.Result> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.noContent:
        return [];
      case HttpStatusCode.unauthorized:
        throw new UnauthorizedError();
      default:
        throw new UnexpectedError();
    }
  }
}
