import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { ICreateVision } from '../use-cases/contracts/create-vision';
import { CreateVisionUseCase } from '../use-cases/create-vision';

export const makeCreateVisionUseCase = (): ICreateVision =>
  new CreateVisionUseCase(
    makeApiUrl(`/vision`),
    makeAuthorizeHttpClientDecorator(),
  );
