import React from 'react';

import { FormControl } from '../atoms/form-control';
import { FormLabel } from '../atoms/form-label';
import { InputText } from '../atoms/input-text';
import { TextError } from '../atoms/text-error';

type Props = {
  id: string;
  label: string;
  textError: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: string;
};

const InputForm: React.FC<Props> = ({
  id,
  label,
  textError,
  onChange,
  defaultValue,
}: Props) => {
  return (
    <FormControl id={id} isInvalid={!!textError}>
      <FormLabel>{label}</FormLabel>
      <InputText onChange={onChange} defaultValue={defaultValue} />
      <TextError>{textError}</TextError>
    </FormControl>
  );
};

export { InputForm };
