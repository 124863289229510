import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';

import { CreateEditGroup } from '../molecules/create-edit-group';
import { DeleteGroup } from '../molecules/delete-group';

const Group: React.FC = () => {
  const { currentGroup, isOpenModal, isOpenDialog, typeModal } = useSelector(
    (state: RootState) => state.group,
  );

  return (
    <>
      <CreateEditGroup
        currentGroup={currentGroup}
        isOpenModal={isOpenModal}
        typeModal={typeModal}
      />
      <DeleteGroup
        currentGroupId={currentGroup.id}
        isOpenDialog={isOpenDialog}
      />
    </>
  );
};

export { Group };
