import React, { useEffect } from 'react';

import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import {
  ModalOverlay,
  Modal as ChakraModal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/modal';

type Props = {
  title: string;
  children: React.ReactNode;
  handlePressSave?: () => void;
  handlePressCancel: () => void;
  visible: boolean;
  isLoading?: boolean;
  isCentered?: boolean;
  size?: string;
  buttonsVisible?: boolean;
};

const Modal: React.FC<Props> = ({
  children,
  title,
  handlePressCancel,
  handlePressSave,
  visible,
  isLoading = false,
  isCentered = false,
  size = 'md',
  buttonsVisible = true,
}: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (visible) {
      onOpen();
    } else {
      onClose();
    }
  }, [visible, onOpen, onClose]);

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      size={size}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton onClick={handlePressCancel} />

        <ModalBody
          borderBottomColor="gray.300"
          borderBottomWidth="1px"
          borderBottomStyle="solid"
          borderTopColor="gray.300"
          borderTopWidth="1px"
          borderTopStyle="solid"
          p={5}
        >
          {children}
        </ModalBody>

        {buttonsVisible && (
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={handlePressSave}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Salvar
            </Button>
            <Button onClick={handlePressCancel}>Cancelar</Button>
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
};

export { Modal };
