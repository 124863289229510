import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';
import { Modal } from '@/shared/components/atoms/modal';
import { InputForm } from '@/shared/components/molecules/input-form';

import { makeCreatePanelsUseCase } from '../../factories/make-create-panels-use-case';
import { closeModal } from '../../state/actions';
import { setSelectedPanels as setSelectedPanelsState } from '../../state/actions';
import { ICreatePanels } from '../../use-cases/contracts/create-panels';

type FormData = {
  link: string;
};

const CreatePanel: React.FC = () => {
  const dispatch = useDispatch();

  const {
    panel: { isOpenModal },
    vision,
  } = useSelector((state: RootState) => state);

  const [formData, setFormData] = useState<FormData>({
    link: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handlePressCancel = useCallback((): void => {
    dispatch(closeModal());
  }, [dispatch]);

  const handlePressSave = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true);
      const url = new URL(formData.link);
      const host = url.host;

      const urlSearchParams = new URLSearchParams(url.search);
      const { appid, sheet } = Object.fromEntries(urlSearchParams.entries());

      const objectURL = `https://${host}/single/?appid=${appid}&sheet=${sheet}`;

      const newPanel = {
        id: '',
        isFull: false,
        url: objectURL,
        name: '',
      };

      const createPanelsUseCase: ICreatePanels = makeCreatePanelsUseCase();
      const savedPanel = await createPanelsUseCase.execute({
        panels: [newPanel],

        visionId: vision?.currentVision?.id,
      });

      if (savedPanel && savedPanel.length > 0) {
        dispatch(
          setSelectedPanelsState(savedPanel[0], vision?.currentVision?.id),
        );
        dispatch(closeModal());
      }
    } catch (error) {
      console.log('Link invalido');
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, vision, formData]);

  useEffect(() => {
    if (isOpenModal) {
      setFormData({
        link: '',
      });
    }
  }, [isOpenModal]);

  return (
    <Modal
      title={`Cadastro de novo painel | ${vision?.currentVision?.name}`}
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={isOpenModal}
      isLoading={isLoading}
      size="md"
    >
      <InputForm
        id="new-panel"
        label="Novo painel"
        onChange={(e) =>
          setFormData((prevState) => ({
            ...prevState,
            link: e.target.value,
          }))
        }
        textError={''}
      />
    </Modal>
  );
};

export { CreatePanel };
