import { action } from 'typesafe-actions';

import { SpaceModel } from '../models/space';
import { ActionTypes } from './types';

export const setCurrentSpace = (data: SpaceModel) =>
  action(ActionTypes.SET_CURRENT_SPACE, { data });

export const setSpaces = (data: SpaceModel[]) =>
  action(ActionTypes.SET_SPACES, { data });

export const setNewSpace = (data: SpaceModel) =>
  action(ActionTypes.SET_NEW_SPACE, { data });

export const editSpace = (data: SpaceModel) =>
  action(ActionTypes.EDIT_SPACE, { data });

export const removeSpace = (id: string) =>
  action(ActionTypes.REMOVE_SPACE, { id });

export const openModal = (typeModal: 'add' | 'edit') =>
  action(ActionTypes.OPEN_MODAL, { typeModal });

export const closeModal = () => action(ActionTypes.CLOSE_MODAL);

export const openDialog = () => action(ActionTypes.OPEN_DIALOG);

export const closeDialog = () => action(ActionTypes.CLOSE_DIALOG);
