import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IRemoveAssociateUserVision } from './contracts/remove-associate-user-vison';

export class RemoveAssociateUserVisionUseCase
  implements IRemoveAssociateUserVision
{
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IRemoveAssociateUserVision.Result>,
  ) {}

  async execute(): Promise<IRemoveAssociateUserVision.Result> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'delete',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        return;
      default:
        throw new UnexpectedError();
    }
  }
}
