import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  Avatar,
  Flex,
  VStack,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Text,
} from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';

const Profile: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.auth);

  const history = useHistory();

  const handlePressSignout = useCallback(async () => {
    history.replace('/sign-out');
  }, [history]);

  return (
    <Flex alignItems={'center'}>
      <VStack mr="20px" align="flex-end">
        <Text color="whiteAlpha.900">{user.name}</Text>
        <Text color="whiteAlpha.800">{user.email}</Text>
      </VStack>
      <Menu>
        <MenuButton
          as={Button}
          rounded={'full'}
          variant={'link'}
          cursor={'pointer'}
          minW={0}
        >
          <Avatar
            size={'md'}
            name={user.name || user.email}
            bg="green.800"
            color="whiteAlpha.900"
          />
        </MenuButton>
        <MenuList>
          <MenuItem onClick={handlePressSignout}>Sair</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export { Profile };
