import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IGetRelatedUsersByVisionId } from '../use-cases/contracts/get-related-users-by-vision-id';
import { GetRelatedUsersByVisionIdUseCase } from '../use-cases/get-related-users-by-vision-id';

export const makeGetRelatedUsersByVisionIdUseCase = (
  visionId: string,
): IGetRelatedUsersByVisionId =>
  new GetRelatedUsersByVisionIdUseCase(
    makeApiUrl(`/user-vision/${visionId}`),
    makeAuthorizeHttpClientDecorator(),
  );
