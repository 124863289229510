import React from 'react';
import { IconType } from 'react-icons/lib';

import {
  Icon,
  Link as ChakraLink,
  Text,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';

type Props = ChakraLinkProps & {
  icon: IconType;
};

const NavLink: React.FC<Props> = ({
  icon,
  onClick,
  children,
  ...rest
}: Props) => {
  return (
    <ChakraLink display="flex" align="center" {...rest} onClick={onClick}>
      <Icon
        as={icon}
        fontSize="20"
        color="whiteAlpha.900"
        stroke="whiteAlpha.900"
        __css={{
          path: {
            stroke: 'var(--chakra-colors-whiteAlpha-900)',
          },
        }}
      />
      <Text ml="4" fontWeight="medium" color="whiteAlpha.900">
        {children}
      </Text>
    </ChakraLink>
  );
};

export { NavLink };
