import produce from 'immer';
import { Reducer } from 'redux';

import { ActionTypes as ActionVisionTypes } from '@/modules/vision/state/types';

import { GroupState, ActionTypes } from './types';

const INITIAL_STATE: GroupState = {
  currentGroup: {
    id: '',
    name: '',
    slug: '',
  },
  groups: [],
  isOpenModal: false,
  isOpenDialog: false,
  typeModal: 'add',
};

const group: Reducer<GroupState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SET_CURRENT_GROUP: {
        draft.currentGroup = action.payload.data;
        break;
      }
      case ActionTypes.SET_GROUPS: {
        draft.groups = action.payload.data;
        if (action.payload.data.length > 0) {
          draft.currentGroup = action.payload.data[0];
        }
        break;
      }
      case ActionTypes.SET_NEW_GROUP: {
        draft.groups = [...draft.groups, action.payload.data];
        draft.isOpenModal = false;
        draft.currentGroup = action.payload.data;
        break;
      }
      case ActionTypes.SET_NEW_VISION: {
        draft.groups = draft.groups.map((group) =>
          group.id === action.payload.data.groupId
            ? {
                ...group,
                visions: group?.visions
                  ? [...group.visions, action.payload.data]
                  : [action.payload.data],
              }
            : { ...group },
        );

        break;
      }
      case ActionTypes.OPEN_MODAL: {
        draft.isOpenModal = true;
        draft.typeModal = action.payload.typeModal;
        break;
      }
      case ActionTypes.CLOSE_MODAL: {
        draft.isOpenModal = false;
        break;
      }
      case ActionVisionTypes.UPDATE_LAYOUT: {
        draft.groups = draft.groups.map((group) => ({
          ...group,
          visions: group.visions?.map((vision) =>
            vision.id === action.payload.visionId
              ? {
                  ...vision,
                  layout: action.payload.layout,
                }
              : { ...vision },
          ),
        }));

        break;
      }
      case ActionTypes.EDIT_GROUP: {
        const data = action.payload.data;
        draft.groups = draft.groups.map((group) =>
          group.id === data.id ? data : group,
        );
        draft.isOpenModal = false;
        draft.currentGroup = action.payload.data;
        break;
      }
      case ActionTypes.REMOVE_GROUP: {
        draft.groups = draft.groups.filter(
          (group) => group.id !== action.payload.id,
        );
        draft.isOpenDialog = false;
        draft.currentGroup =
          draft.groups.length > 0
            ? draft.groups[0]
            : {
                id: '',
                name: '',
                slug: '',
              };
        break;
      }
      case ActionTypes.OPEN_DIALOG: {
        draft.isOpenDialog = true;
        break;
      }
      case ActionTypes.CLOSE_DIALOG: {
        draft.isOpenDialog = false;
        break;
      }
      default:
    }
  });
};

export default group;
