import React from 'react';
import env from 'react-dotenv';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { RouteWrapper } from '@/main/proxies/route';
import { Dashboard } from '@/modules/dashboard/components/pages/dashboard';
import { makeQlikUsersPage } from '@/modules/qlik-users/factories/make-qlik-users-page';
import { makeSignInPage } from '@/modules/sign-in/factories/make-sign-in-page';
import Maintenance from '@/shared/components/pages/maintenance';

import { ChakraProvider } from '../providers/chakra-provider';
import { ReduxProvider } from '../providers/redux-provider';

const Router: React.FC = () => {
  if (env.MAINTENANCE) {
    return <Maintenance />;
  }

  return (
    <ReduxProvider>
      <ChakraProvider>
        <BrowserRouter>
          <Switch>
            <RouteWrapper path="/" exact component={makeSignInPage} />

            <RouteWrapper
              path="/dashboard"
              exact
              component={Dashboard}
              isPrivate
            />

            <RouteWrapper
              path="/user"
              exact
              component={makeQlikUsersPage}
              isPrivate
            />

            <Route
              path="/sign-out"
              component={() => {
                window.location.href =
                  'https://athenasagricola.us.qlikcloud.com/logout';
                return null;
              }}
            />
          </Switch>
        </BrowserRouter>
      </ChakraProvider>
    </ReduxProvider>
  );
};

export default Router;
