import { Layouts } from 'react-grid-layout';

import { action } from 'typesafe-actions';

import { VisionModel } from '../models/vision';
import { ActionTypes } from './types';

export const setCurrentVision = (data: VisionModel) =>
  action(ActionTypes.SET_CURRENT_VISION, { data });

export const updateLayout = (layout: Layouts | undefined, visionId: string) =>
  action(ActionTypes.UPDATE_LAYOUT, { layout, visionId });

export const setGroups = (data: VisionModel[]) =>
  action(ActionTypes.SET_VISIONS, { data });

export const setNewVision = (data: VisionModel) =>
  action(ActionTypes.SET_NEW_VISION, { data });

export const openModal = () => action(ActionTypes.OPEN_MODAL);

export const closeModal = () => action(ActionTypes.CLOSE_MODAL);

export const openModalShare = () => action(ActionTypes.OPEN_MODAL_SHARE);

export const closeModalShare = () => action(ActionTypes.CLOSE_MODAL_SHARE);
