import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex } from '@chakra-ui/layout';
import { CircularProgress } from '@chakra-ui/progress';
import { useToast } from '@chakra-ui/toast';

import { RootState } from '@/main/config/redux';
import { Group } from '@/modules/group/components/organisms/group';
import { makeGetGroupsBySpaceIDUseCase } from '@/modules/group/factories/make-get-groups-by-space-id-use-case';
import { setCurrentGroup, setGroups } from '@/modules/group/state/actions';
import { CreatePanel } from '@/modules/panel/components/molecules/create-panel';
import { GridLayoutPanel } from '@/modules/panel/components/molecules/grid-layout-panel';
import { makeGetPanelsByVisionIdUseCase } from '@/modules/panel/factories/make-get-panels-by-vision-id-use-case';
import { setPanels } from '@/modules/panel/state/actions';
import { Space } from '@/modules/space/components/organisms/space';
import { makeGetSpacesByAuthenticationUseCase } from '@/modules/space/factories/make-get-spaces-by-authentication-use-case';
import { setSpaces } from '@/modules/space/state/actions';
import { CreateVision } from '@/modules/vision/components/molecules/create-vision';
import { ShareVision } from '@/modules/vision/components/molecules/share-vision';
import { makeGetVisionUseCase } from '@/modules/vision/factories/make-get-vision-use-case';
import { setCurrentVision } from '@/modules/vision/state/actions';
import { updateLayout } from '@/modules/vision/state/actions';
import { sleep } from '@/shared/utils/sleep';

const Dashboard: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();

  const { space, vision, group } = useSelector((state: RootState) => state);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const resetGroup = useCallback(() => {
    dispatch(setGroups([]));
    dispatch(
      setCurrentGroup({
        id: '',
        name: '',
        slug: '',
        visions: [],
      }),
    );
  }, [dispatch]);

  const resetVision = useCallback(() => {
    dispatch(
      setCurrentVision({
        id: '',
        layout: undefined,
        name: '',
        slug: '',
      }),
    );
  }, [dispatch]);

  const resetPanel = useCallback(() => {
    dispatch(setPanels([]));
  }, [dispatch]);

  //carregar espaços
  useEffect(() => {
    (async () => {
      const loadSpacesUseCase = makeGetSpacesByAuthenticationUseCase();

      try {
        const spaces = await loadSpacesUseCase.execute();
        if (spaces) {
          dispatch(setSpaces(spaces));
        }
      } catch (error) {
        toast({
          title: 'Ocorreu um erro ao tentar listar os espaços.',
          description: (error as Error).message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    })();
  }, [toast, dispatch]);

  //carregar grupos
  useEffect(() => {
    (async () => {
      if (space?.currentSpace?.id) {
        const loadGroupsUseCase = makeGetGroupsBySpaceIDUseCase();

        try {
          const groups = await loadGroupsUseCase.execute();

          //@ts-ignore
          if (groups && groups.length > 0) {
            dispatch(setGroups(groups));

            if (groups[0]?.visions) {
              dispatch(setCurrentVision(groups[0]?.visions[0]));
            } else {
              resetVision();
              resetPanel();
            }
          } else {
            resetGroup();
            resetVision();
            resetPanel();
          }
        } catch (error) {
          resetVision();
          resetPanel();
          toast({
            title: 'Ocorreu um erro ao tentar listar os grupos desse espaço.',
            description: (error as Error).message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
      } else {
        resetGroup();
      }
    })();
  }, [
    space.currentSpace.id,
    dispatch,
    toast,
    resetGroup,
    resetPanel,
    resetVision,
  ]);

  //carregar paineis
  useEffect(() => {
    (async () => {
      if (vision?.currentVision?.id) {
        setIsLoading(true);

        const getVisionUseCase = makeGetVisionUseCase(vision.currentVision.id);

        const data = await getVisionUseCase.execute();

        if (data) {
          dispatch(updateLayout(data.layout, data.id));
          const getPanelsByVisionIdUseCase = makeGetPanelsByVisionIdUseCase(
            vision?.currentVision?.id,
          );

          try {
            const panels = await getPanelsByVisionIdUseCase.execute();

            //@ts-ignore
            if (panels && panels.length > 0) {
              dispatch(setPanels(panels));
            } else {
              resetPanel();
            }
          } catch (error) {
            resetPanel();
            toast({
              title: 'Ocorreu um erro ao tentar listar os paineis dessa visão.',
              description: (error as Error).message,
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          } finally {
            await sleep(1000);
            setIsLoading(false);
          }
        } else {
          resetPanel();
        }
      } else {
        resetPanel();
      }
    })();
  }, [vision.currentVision.id, toast, dispatch, resetPanel]);

  //limpar paineis caso nao tenha visoes no grupo
  useEffect(() => {
    if (!group?.currentGroup?.visions) {
      resetVision();
      resetPanel();
    }
  }, [group.currentGroup.visions, resetPanel, resetVision]);

  return (
    <Flex flex="1">
      <Space />
      <Group />
      <CreateVision />
      <CreatePanel />
      <ShareVision />

      {isLoading ? (
        <Flex flex="1" alignItems="center" justifyContent="center">
          <CircularProgress isIndeterminate size="150px" color="green.500" />
        </Flex>
      ) : (
        <GridLayoutPanel />
      )}
    </Flex>
  );
};

export { Dashboard };
