import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IGetRelatedUsersByVisionId } from './contracts/get-related-users-by-vision-id';

export class GetRelatedUsersByVisionIdUseCase
  implements IGetRelatedUsersByVisionId
{
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IGetRelatedUsersByVisionId.Result>,
  ) {}

  async execute(): Promise<IGetRelatedUsersByVisionId.Result> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      default:
        throw new UnexpectedError();
    }
  }
}
