import React, { useMemo } from 'react';

import { Flex } from '@chakra-ui/layout';

type Props = {
  url: string;
  name: string;
  isFull: boolean;
};

const Iframe: React.FC<Props> = ({ url, name, isFull }: Props) => {
  return (
    <Flex
      flex="1"
      as="iframe"
      minH="98%"
      minW="98%"
      src={`${url}&lang=pt-BR&theme=athenas%20full%20page&opt=ctxmenu,currsel&identity=preview`}
      borderBottomLeftRadius="8px"
      borderBottomRightRadius="8px"
    />
  );
};

export { Iframe };
