import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Modal } from '@/shared/components/atoms/modal';
import { InputForm } from '@/shared/components/molecules/input-form';

import { makeEditSpaceUseCase } from '../../factories/make-edit-space-use-case';
import { makeSaveNewSpaceUseCase } from '../../factories/make-save-new-space-use-case';
import { SpaceModel } from '../../models/space';
import { closeModal, editSpace, setNewSpace } from '../../state/actions';

type InputProps = {
  name: string;
};

type Props = {
  isOpenModal: boolean;
  currentSpace: SpaceModel;
  typeModal: 'add' | 'edit';
};

const CreateEditSpace: React.FC<Props> = ({
  currentSpace,
  isOpenModal,
  typeModal,
}: Props) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState<InputProps>({ name: '' });
  const [textError, setTextError] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCreate = useCallback(async () => {
    const saveNewSpaceUseCase = makeSaveNewSpaceUseCase();
    const space = await saveNewSpaceUseCase.execute({ name: formData.name });

    if (space) {
      dispatch(setNewSpace(space));
    }
  }, [dispatch, formData]);

  const handleEdit = useCallback(async () => {
    const editSpaceUseCase = makeEditSpaceUseCase();
    const space = await editSpaceUseCase.execute({
      name: formData.name,
      id: currentSpace.id,
    });
    if (space) {
      dispatch(editSpace(space));
    }
  }, [dispatch, formData, currentSpace]);

  const handlePressSave = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    try {
      if (typeModal == 'add') {
        await handleCreate();
      } else {
        await handleEdit();
      }
    } catch (error) {
      setTextError((error as Error).message);
    } finally {
      setFormData({ name: '' });
      setIsLoading(false);
    }
  }, [handleCreate, handleEdit, typeModal]);

  const handlePressCancel = useCallback((): void => {
    setFormData({ name: '' });
    dispatch(closeModal());
  }, [dispatch]);

  useEffect(() => {
    if (typeModal == 'edit') {
      setFormData({ name: currentSpace.name });
    } else {
      setFormData({ name: '' });
    }
    setIsLoading(false);
    setTextError('');
  }, [typeModal, currentSpace]);

  return (
    <Modal
      title={
        typeModal === 'edit' ? 'Edição de espaço' : 'Cadastro de novo espaço'
      }
      handlePressCancel={handlePressCancel}
      handlePressSave={handlePressSave}
      visible={isOpenModal}
      isLoading={isLoading}
    >
      <InputForm
        id="new-space"
        label="Novo espaço"
        onChange={(e) => setFormData({ name: e.target.value })}
        defaultValue={formData.name}
        textError={textError}
      />
    </Modal>
  );
};

export { CreateEditSpace };
