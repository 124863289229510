import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { IUpdateLayoutByVisionId } from './contracts/update-layout-by-vision-id';

export class UpdateLayoutBiVsionIdUseCase implements IUpdateLayoutByVisionId {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IUpdateLayoutByVisionId.Result>,
  ) {}

  async execute(
    params: IUpdateLayoutByVisionId.Params,
  ): Promise<IUpdateLayoutByVisionId.Result> {
    const { layout } = params;

    const stringifyLayout = JSON.stringify(layout);

    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'put',
      body: { layout: stringifyLayout },
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      default:
        throw new UnexpectedError();
    }
  }
}
