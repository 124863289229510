import produce from 'immer';
import { Reducer } from 'redux';

import { SpaceState, ActionTypes } from './types';

const INITIAL_STATE: SpaceState = {
  currentSpace: {
    id: '',
    name: '',
    slug: '',
  },
  spaces: [],
  isOpenModal: false,
  isOpenDialog: false,
  typeModal: 'add',
};

const space: Reducer<SpaceState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SET_CURRENT_SPACE: {
        draft.currentSpace = action.payload.data;
        break;
      }
      case ActionTypes.SET_SPACES: {
        draft.spaces = action.payload.data;
        draft.currentSpace = action.payload.data[0];
        break;
      }
      case ActionTypes.SET_NEW_SPACE: {
        draft.spaces = [...draft.spaces, action.payload.data];
        draft.isOpenModal = false;
        draft.currentSpace = action.payload.data;
        break;
      }
      case ActionTypes.EDIT_SPACE: {
        const data = action.payload.data;
        draft.spaces = draft.spaces.map((space) =>
          space.id === data.id ? data : space,
        );
        draft.isOpenModal = false;
        draft.currentSpace = action.payload.data;
        break;
      }
      case ActionTypes.REMOVE_SPACE: {
        draft.spaces = draft.spaces.filter(
          (space) => space.id !== action.payload.id,
        );
        draft.isOpenDialog = false;
        draft.currentSpace = draft.spaces[0];
        break;
      }
      case ActionTypes.OPEN_MODAL: {
        draft.isOpenModal = true;
        draft.typeModal = action.payload.typeModal;
        break;
      }
      case ActionTypes.CLOSE_MODAL: {
        draft.isOpenModal = false;
        break;
      }
      case ActionTypes.OPEN_DIALOG: {
        draft.isOpenDialog = true;
        break;
      }
      case ActionTypes.CLOSE_DIALOG: {
        draft.isOpenDialog = false;
        break;
      }
      default:
    }
  });
};

export default space;
