import React, { useMemo } from 'react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { useSelector } from 'react-redux';

import { Flex, Icon, Button, Text } from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';

import { Menu } from './menu';

const DropDownBox: React.FC = () => {
  const { space, group, vision } = useSelector((state: RootState) => state);

  const renderPlaceHolder = useMemo((): string => {
    let placeHolder = space?.currentSpace?.name;

    if (group?.currentGroup?.name) {
      placeHolder += ` > ${group.currentGroup.name}`;
    }

    if (vision?.currentVision?.name) {
      placeHolder += ` > ${vision.currentVision.name}`;
    }

    return placeHolder;
  }, [space, group, vision]);

  return (
    <Popover trigger={'click'} placement={'bottom'}>
      <PopoverTrigger>
        <Flex
          minW="500px"
          h="40px"
          borderRadius="6px"
          backgroundColor="whiteAlpha.900"
          borderWidth="1"
          borderStyle="solid"
          borderColor="gray.400"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex alignItems="center">
            <Text marginLeft="1" color="black" fontWeight="bold">
              {renderPlaceHolder}
            </Text>
          </Flex>

          <Button
            backgroundColor="transparent"
            _hover={{
              backgroundColor: 'gray.200',
            }}
            _focus={{
              boxShadow: 'none',
            }}
          >
            <Icon as={AiOutlineCaretDown} />
          </Button>
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        _focus={{
          boxShadow: 'none',
        }}
        border="0"
      >
        <PopoverBody border="0">
          <Menu />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export { DropDownBox };
