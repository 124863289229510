import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IGetSpacesByAuthentication } from '../use-cases/contracts/get-spaces-by-authentication';
import { GetSpacesByAuthenticationUseCase } from '../use-cases/get-spaces-by-authentication';

export const makeGetSpacesByAuthenticationUseCase =
  (): IGetSpacesByAuthentication =>
    new GetSpacesByAuthenticationUseCase(
      makeApiUrl('/space'),
      makeAuthorizeHttpClientDecorator(),
    );
