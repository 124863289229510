import { HttpClient } from '@/shared/adapters/http/contracts/http-client';

import { IDeletePanelById } from './contracts/delete-panel-by-ids';

export class DeletePanelByIdUseCase implements IDeletePanelById {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<IDeletePanelById.Result>,
  ) {}

  async execute(): Promise<IDeletePanelById.Result> {
    await this.httpClient.request({
      url: this.url,
      method: 'delete',
    });
  }
}
