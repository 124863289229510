import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { ISaveNewGroup } from '../use-cases/contracts/save-new-group';
import { SaveNewGroupUseCase } from '../use-cases/save-group-space';

export const makeSaveNewGroupUseCase = (): ISaveNewGroup =>
  new SaveNewGroupUseCase(
    makeApiUrl('/group'),
    makeAuthorizeHttpClientDecorator(),
  );
