import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { ICreatePanels } from '../use-cases/contracts/create-panels';
import { CreatePanelsUseCase } from '../use-cases/create-panels.ts';

export const makeCreatePanelsUseCase = (): ICreatePanels =>
  new CreatePanelsUseCase(
    makeApiUrl('/panel'),
    makeAuthorizeHttpClientDecorator(),
  );
