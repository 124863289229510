import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IGetGroupsBySpaceId } from '../use-cases/contracts/get-groups-by-space-id';
import { GetGroupsBySpaceId } from '../use-cases/get-groups-by-space-id';

export const makeGetGroupsBySpaceIDUseCase = (): IGetGroupsBySpaceId =>
  new GetGroupsBySpaceId(
    makeApiUrl('/group'),
    makeAuthorizeHttpClientDecorator(),
  );
