import React, { useCallback, useEffect, useState } from 'react';
import { RiRefreshLine } from 'react-icons/ri';

import {
  Table,
  Flex,
  Thead,
  Tbody,
  Checkbox,
  Tr,
  Th,
  Text,
  Td,
  Button,
  Icon,
  useToast,
} from '@chakra-ui/react';

import { makeGetAllUserQlikUseCase } from '../../factories/make-get-all-users-qlik-use-case';
import { UserModel } from '../../models/user-qlik';
import { IGetAllUsers } from '../../use-cases/contracts/get-all-users';

type Props = {
  getAllUsersUseCase: IGetAllUsers;
};

const QlikUsers: React.FC<Props> = ({ getAllUsersUseCase }: Props) => {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserModel[]>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await getAllUsersUseCase.execute();

        if (response) {
          setUsers(response);
        }
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [getAllUsersUseCase]);

  const refreshUsers = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    const getAllUserQlikUseCase = makeGetAllUserQlikUseCase();

    try {
      const updatedUsers = await getAllUserQlikUseCase.execute();

      //@ts-ignore
      if (updatedUsers && updatedUsers.length > 0) {
        setUsers(updatedUsers);
      }
    } catch (error) {
      toast({
        title: 'Ocorreu um erro ao atualizas os usuarios.',
        description: (error as Error).message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

  return (
    <Flex
      paddingTop="50"
      flex="1"
      alignItems="center"
      justifyContent="flex-start"
      flexDir="column"
      marginLeft="10%"
      marginRight="10%"
    >
      <Flex
        w="100%"
        height="80px"
        backgroundColor="green.500"
        alignItems="center"
        paddingLeft="10"
        paddingRight="10"
        justifyContent="space-between"
      >
        <Text fontSize="24px" color="whiteAlpha.900">
          Usuários
        </Text>
        <Button
          size="md"
          bgColor="whiteAlpha.700"
          cursor="pointer"
          onClick={refreshUsers}
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          <Icon as={RiRefreshLine} color="black.800" fontSize={20} />
        </Button>
      </Flex>

      <Table
        variant="striped"
        colorScheme="black"
        borderWidth="1"
        borderStyle="solid"
        borderColor="gray.600"
      >
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>Nome</Th>
            <Th>Id Qlik</Th>
            <Th>Licença Qlik</Th>
            <Th>Administrador</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users.map((user) => (
            <Tr key={user.id}>
              <Td>{user.email}</Td>
              <Td>{user.name}</Td>
              <Td>{user.idQlik}</Td>
              <Td>{user.licenseQlik}</Td>
              <Td>
                <Checkbox
                  isChecked={user.isAdmin}
                  isDisabled
                  colorScheme="green"
                ></Checkbox>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Flex>
  );
};

export { QlikUsers };
