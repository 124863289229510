import produce from 'immer';
import { Reducer } from 'redux';

import { PanelState, ActionTypes } from './types';

const INITIAL_STATE: PanelState = {
  currentPanel: {
    id: '',
    name: '',
    url: '',
    isFull: false,
  },
  panels: [],
  isOpenModal: false,
};

const panel: Reducer<PanelState> = (state = INITIAL_STATE, action) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.SET_CURRENT_PANEL: {
        draft.currentPanel = action.payload.data;
        break;
      }
      case ActionTypes.SET_PANELS: {
        draft.panels = action.payload.data;
        draft.currentPanel = action.payload.data[0];
        break;
      }
      case ActionTypes.SET_NEW_PANEL: {
        draft.panels = [...draft.panels, action.payload.data];
        draft.isOpenModal = false;
        draft.currentPanel = action.payload.data;
        break;
      }
      case ActionTypes.REMOVE_PANEL: {
        draft.panels = draft.panels.filter(
          (panel) => panel.id !== action.payload.id,
        );

        break;
      }
      case ActionTypes.SET_SELECTED_PANELS: {
        draft.panels.push(action.payload.newPanel);
        break;
      }
      case ActionTypes.OPEN_MODAL: {
        draft.isOpenModal = true;
        break;
      }
      case ActionTypes.CLOSE_MODAL: {
        draft.isOpenModal = false;
        break;
      }
      default:
    }
  });
};

export default panel;
