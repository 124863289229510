import React from 'react';

import {
  FormControl as ChakraFormControl,
  FormControlProps,
} from '@chakra-ui/react';

const FormControl: React.FC<FormControlProps> = ({
  children,
  ...rest
}: FormControlProps) => {
  return <ChakraFormControl {...rest}>{children}</ChakraFormControl>;
};

export { FormControl };
