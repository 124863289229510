import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IEditSpace } from '../use-cases/contracts/edit-space';
import { EditSpaceUseCase } from '../use-cases/edit-space';

export const makeEditSpaceUseCase = (): IEditSpace =>
  new EditSpaceUseCase(
    makeApiUrl('/space'),
    makeAuthorizeHttpClientDecorator(),
  );
