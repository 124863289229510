import React, { useCallback } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { AiOutlineGroup } from 'react-icons/ai';
import { FaElementor } from 'react-icons/fa';
import { GrGroup, GrObjectGroup } from 'react-icons/gr';
import { IoMdAdd, IoMdCreate, IoMdTrash } from 'react-icons/io';
import { MdShare } from 'react-icons/md';
import { MdDashboard } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Stack } from '@chakra-ui/react';

import { RootState } from '@/main/config/redux';
import {
  openModal as openNewGroupModal,
  openDialog as openDeleteGroupDialog,
} from '@/modules/group/state/actions';
import { openModal as openNewPanelModal } from '@/modules/panel/state/actions';
import {
  openModal as openNewSpaceModal,
  openDialog as openDeleteSpaceDialog,
} from '@/modules/space/state/actions';
import { openModal as openNewVisionModal } from '@/modules/vision/state/actions';
import { openModalShare } from '@/modules/vision/state/actions';

import { NavLink } from '../atoms/nav-link';
import { NavSection } from '../atoms/nav-section';

type Props = {
  onClose: () => void;
};

type Method = 'add' | 'edit' | 'delete';

const SidebarNav: React.FC<Props> = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    auth: { user },
    space,
    group,
    vision,
  } = useSelector((state: RootState) => state);

  const handlePressDashboard = useCallback(() => {
    history.push('/dashboard');
    onClose();
  }, [onClose, history]);

  const handlePressSpace = useCallback(
    (method: Method) => {
      if (method === 'delete') {
        dispatch(openDeleteSpaceDialog());
      } else {
        dispatch(openNewSpaceModal(method));
      }
      onClose();
    },
    [onClose, dispatch],
  );

  const handlePressGroup = useCallback(
    (method: Method) => {
      if (method === 'delete') {
        dispatch(openDeleteGroupDialog());
      } else {
        dispatch(openNewGroupModal(method));
      }
      onClose();
    },
    [onClose, dispatch],
  );

  const handlePressNewVision = useCallback(() => {
    dispatch(openNewVisionModal());
    onClose();
  }, [onClose, dispatch]);

  const handlePressNewPanel = useCallback(() => {
    dispatch(openNewPanelModal());
    onClose();
  }, [onClose, dispatch]);

  const hanldePressQlikUsers = useCallback(() => {
    history.push('/user');
    onClose();
  }, [history, onClose]);

  const hanldePressShareVision = useCallback(() => {
    dispatch(openModalShare());
    onClose();
  }, [dispatch, onClose]);

  return (
    <Stack spacing="5" align="flex-start">
      <NavLink onClick={handlePressDashboard} icon={MdDashboard}>
        Dashboard
      </NavLink>

      {user.isAdmin && (
        <>
          <NavSection title="Espaço">
            <NavLink onClick={() => handlePressSpace('add')} icon={IoMdAdd}>
              Novo espaço
            </NavLink>

            {space?.currentSpace?.id && (
              <>
                <NavLink
                  onClick={() => handlePressSpace('edit')}
                  icon={IoMdCreate}
                >
                  Editar espaço
                </NavLink>

                <NavLink
                  onClick={() => handlePressSpace('delete')}
                  icon={IoMdTrash}
                >
                  Remover espaço
                </NavLink>
              </>
            )}
          </NavSection>

          {space?.currentSpace?.id && (
            <NavSection title="Grupo">
              <NavLink onClick={() => handlePressGroup('add')} icon={IoMdAdd}>
                Novo grupo
              </NavLink>

              {group?.currentGroup?.id && (
                <>
                  <NavLink
                    onClick={() => handlePressGroup('edit')}
                    icon={IoMdCreate}
                  >
                    Editar grupo
                  </NavLink>

                  <NavLink
                    onClick={() => handlePressGroup('delete')}
                    icon={IoMdTrash}
                  >
                    Remover grupo
                  </NavLink>
                </>
              )}
            </NavSection>
          )}

          {group?.currentGroup?.id && (
            <NavSection title="Visão">
              <NavLink onClick={handlePressNewVision} icon={IoMdAdd}>
                Nova visão
              </NavLink>

              {/* {vision?.currentVision?.id && (
                <>
                  <NavLink
                    onClick={() => {
                      console.log('Ok');
                    }}
                    icon={IoMdCreate}
                  >
                    Editar visão
                  </NavLink>

                  <NavLink
                    onClick={() => {
                      console.log('Ok');
                    }}
                    icon={IoMdTrash}
                  >
                    Remover visão
                  </NavLink>
                </>
              )} */}
            </NavSection>
          )}

          {vision?.currentVision?.id && (
            <NavSection title="Painel">
              <NavLink onClick={handlePressNewPanel} icon={IoMdAdd}>
                Novo painel
              </NavLink>
            </NavSection>
          )}

          <NavSection title="GERENCIAMENTO">
            <NavLink onClick={hanldePressQlikUsers} icon={AiOutlineUser}>
              Usuários
            </NavLink>

            {vision?.currentVision?.id && (
              <NavLink onClick={hanldePressShareVision} icon={MdShare}>
                Compartilhar
              </NavLink>
            )}
          </NavSection>
        </>
      )}
    </Stack>
  );
};

export { SidebarNav };
