import { SpaceModel } from '../models/space';

export enum ActionTypes {
  SET_SPACES = '@space/SET_SPACES',
  SET_CURRENT_SPACE = '@space/SET_CURRENT_SPACE',
  SET_NEW_SPACE = '@space/SET_NEW_SPACE',
  REMOVE_SPACE = '@space/REMOVE_SPACE',
  EDIT_SPACE = '@space/EDIT_SPACE',

  OPEN_MODAL = '@space/OPEN_MODAL',
  CLOSE_MODAL = '@space/CLOSE_MODAL',

  OPEN_DIALOG = '@space/OPEN_DIALOG',
  CLOSE_DIALOG = '@space/CLOSE_DIALOG',
}

export type SpaceState = {
  currentSpace: SpaceModel;
  spaces: SpaceModel[];

  isOpenModal: boolean;
  isOpenDialog: boolean;

  typeModal: 'add' | 'edit';
};
