import React from 'react';

import { Box, Stack, Text } from '@chakra-ui/react';

type Props = {
  title: string;
  children: React.ReactNode;
};

const NavSection: React.FC<Props> = ({ title, children }: Props) => {
  return (
    <Box>
      <Text fontWeight="bold" color="gray.400" fontSize="small">
        {title}
      </Text>
      <Stack spacing="4" mt="2" align="stretch">
        {children}
      </Stack>
    </Box>
  );
};

export { NavSection };
