import React from 'react';

import {
  DrawerBody,
  DrawerOverlay,
  DrawerContent as DrawerContentChakra,
} from '@chakra-ui/react';

import { SpaceSwitcher } from '@/modules/space/components/atom/space-switcher';

import { SidebarNav } from './sidebar-nav';

type Props = {
  onClose: () => void;
};

const DrawerContent: React.FC<Props> = ({ onClose }: Props) => {
  return (
    <DrawerOverlay>
      <DrawerContentChakra bg="green.800" p="4">
        <DrawerBody>
          <SpaceSwitcher />
          <SidebarNav onClose={onClose} />
        </DrawerBody>
      </DrawerContentChakra>
    </DrawerOverlay>
  );
};

export { DrawerContent };
