import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IUpdateLayoutByVisionId } from '../use-cases/contracts/update-layout-by-vision-id';
import { UpdateLayoutBiVsionIdUseCase } from '../use-cases/update-layout-by-vision-id';

export const makeUpdateLayoutByVisionIdUseCase = (
  visionId: string,
): IUpdateLayoutByVisionId =>
  new UpdateLayoutBiVsionIdUseCase(
    makeApiUrl(`/vision/${visionId}`),
    makeAuthorizeHttpClientDecorator(),
  );
