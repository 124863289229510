import { HttpClient } from '@/shared/adapters/http/contracts/http-client';
import { HttpStatusCode } from '@/shared/presentation/contracts/http';
import { ApplicationError } from '@/shared/presentation/errors/application-error';
import { UnexpectedError } from '@/shared/presentation/errors/unexpected-error';

import { ISignIn } from './contracts/sign-in';

export class SignInUseCase implements ISignIn {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<ISignIn.Result>,
  ) {}

  async execute(params: ISignIn.Params): Promise<ISignIn.Result> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: params,
    });

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body;
      case HttpStatusCode.forbidden:
        throw new ApplicationError(httpResponse?.body?.error || '');
      default:
        throw new UnexpectedError();
    }
  }
}
