import { action } from 'typesafe-actions';

import { PanelModel } from '../models/panel';
import { ActionTypes } from './types';

export const setPanels = (data: PanelModel[]) =>
  action(ActionTypes.SET_PANELS, { data });

export const setNewPanel = (data: PanelModel) =>
  action(ActionTypes.SET_NEW_PANEL, { data });

export const removePanel = (id: string) =>
  action(ActionTypes.REMOVE_PANEL, { id });

export const setSelectedPanels = (newPanel: PanelModel, visionId: string) =>
  action(ActionTypes.SET_SELECTED_PANELS, { newPanel, visionId });

export const openModal = () => action(ActionTypes.OPEN_MODAL);

export const closeModal = () => action(ActionTypes.CLOSE_MODAL);
