import { PanelModel } from '../models/panel';

export enum ActionTypes {
  SET_PANELS = '@panel/SET_PANELS',
  SET_CURRENT_PANEL = '@panel/SET_CURRENT_PANEL',
  SET_NEW_PANEL = '@panel/SET_NEW_PANEL',
  SET_SELECTED_PANELS = '@panel/SET_SELECTED_PANELS',
  REMOVE_PANEL = '@panel/REMOVE_PANEL',
  OPEN_MODAL = '@panel/OPEN_MODAL',
  CLOSE_MODAL = '@panel/CLOSE_MODAL',
}

export type PanelState = {
  currentPanel: PanelModel;
  panels: PanelModel[];

  isOpenModal: boolean;
};
