import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { GroupState } from '@/modules/group/state/types';
import { PanelState } from '@/modules/panel/state/types';
import { AuthState } from '@/modules/sign-in/state/types';
import { SpaceState } from '@/modules/space/state/types';
import { VisionState } from '@/modules/vision/state/types';

import createStore from './create-store';
import persistReducers from './persist-reducers';
import rootReducer from './root-reducer';
import rootSaga from './root-saga';

export interface RootState {
  auth: AuthState;
  space: SpaceState;
  group: GroupState;
  vision: VisionState;
  panel: PanelState;
}

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const middlewares = [sagaMiddleware];

const store = createStore(persistReducers(rootReducer), middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
