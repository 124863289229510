import { all } from 'redux-saga/effects';

import panel from '@/modules/panel/state/saga';
import auth from '@/modules/sign-in/state/saga';

const rootSaga = function* root() {
  yield all([auth, panel]);
};

export default rootSaga;
