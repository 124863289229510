import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IDeletePanelById } from '../use-cases/contracts/delete-panel-by-ids';
import { DeletePanelByIdUseCase } from '../use-cases/delete-panel-by-id';

export const makeDeletePanelByIdUseCase = (panelId: string): IDeletePanelById =>
  new DeletePanelByIdUseCase(
    makeApiUrl(`/panel/${panelId}`),
    makeAuthorizeHttpClientDecorator(),
  );
