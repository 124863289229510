import { VisionModel } from '../models/vision';

export enum ActionTypes {
  SET_VISIONS = '@vision/SET_VISIONS',
  SET_CURRENT_VISION = '@vision/SET_CURRENT_VISION',
  SET_NEW_VISION = '@vision/SET_NEW_VISION',

  UPDATE_LAYOUT = '@vision/UPDATE_LAYOUT',

  OPEN_MODAL = '@vision/OPEN_MODAL',
  CLOSE_MODAL = '@vision/CLOSE_MODAL',

  OPEN_MODAL_SHARE = '@vision/OPEN_MODAL_SHARE',
  CLOSE_MODAL_SHARE = '@vision/CLOSE_MODAL_SHARE',
}

export type VisionState = {
  currentVision: VisionModel;
  visions: VisionModel[];

  isOpenModal: boolean;
  isOpenModalShare: boolean;
};
