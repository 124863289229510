import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Flex, useToast } from '@chakra-ui/react';

import SelectBox from 'devextreme-react/select-box';

import { RootState } from '@/main/config/redux';

import { setCurrentSpace } from '../../state/actions';

const SpaceSwitcher: React.FC = () => {
  const dispatch = useDispatch();

  const { spaces, currentSpace } = useSelector(
    (state: RootState) => state.space,
  );

  const onValueChange = useCallback(
    (id: string) => {
      const currentSpace = spaces.find((space) => space.id === id);

      if (currentSpace) {
        dispatch(setCurrentSpace(currentSpace));
      }
    },
    [dispatch, spaces],
  );

  return (
    <Flex marginBottom="4">
      <SelectBox
        dataSource={spaces}
        displayExpr="name"
        valueExpr="id"
        onValueChange={onValueChange}
        defaultValue={currentSpace.id || spaces[0].id}
      />
    </Flex>
  );
};

export { SpaceSwitcher };
