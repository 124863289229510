import React from 'react';

import { Flex, Image, Text, VStack } from '@chakra-ui/react';

const Maintenance: React.FC = () => {
  return (
    <Flex
      flex={1}
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Image
        src="/maintenance.svg"
        alt="Maintenance"
        boxSize="50%"
        objectFit="cover"
      />

      <VStack spacing={2}>
        <Text fontSize={35} fontWeight="bold" color="gray.900">
          Estamos apenas ajustando algumas coisas.
        </Text>

        <Text fontSize={35} color="gray.600">
          Pedimos desculpas pelo transtorno.
        </Text>
      </VStack>
    </Flex>
  );
};

export default Maintenance;
