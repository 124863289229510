import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IDeleteGroup } from '../use-cases/contracts/delete-group';
import { DeleteGroupUseCase } from '../use-cases/delete-group';

export const makeDeleteGroupUseCase = (): IDeleteGroup =>
  new DeleteGroupUseCase(
    makeApiUrl('/group'),
    makeAuthorizeHttpClientDecorator(),
  );
