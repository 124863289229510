import env from 'react-dotenv';

import { ISignInQlik } from '../use-cases/contracts/sign-in-qlik';
import { SignInQlikUseCase } from '../use-cases/sign-in-qlik';

export const makeSignInQlikUseCase = (): ISignInQlik => {
  const QLIK_INTEGRATION_ID = String(env.QLIK_INTEGRATION_ID);
  return new SignInQlikUseCase(
    'https://athenasagricola.us.qlikcloud.com/api/v1/users/me',
    QLIK_INTEGRATION_ID,
  );
};
