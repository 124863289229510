import React from 'react';

import { Flex, FlexProps } from '@chakra-ui/layout';

import { Header } from '@/shared/components/organisms/header';

const Main: React.FC<FlexProps> = ({ children }: FlexProps) => {
  return (
    <Flex minH="100vh" bg="gray.100" flexDir="column">
      <Header />

      <Flex flex="1" minH="100%" minW="100%">
        {children}
      </Flex>
    </Flex>
  );
};

export { Main };
