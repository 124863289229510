import React, { useCallback } from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@chakra-ui/button';
import Icon from '@chakra-ui/icon';
import { Flex } from '@chakra-ui/layout';

import { RootState } from '@/main/config/redux';

import { removePanel } from '../../state/actions';

type Props = {
  id: string;
};
const PanelHeader: React.FC<Props> = ({ id }: Props) => {
  const dispatch = useDispatch();

  const { auth } = useSelector((state: RootState) => state);

  const handlePressRemovePanel = useCallback(() => {
    dispatch(removePanel(id));
    //função de remoção do banco de dados esta no redux/saga
  }, [dispatch, id]);

  return auth.user.isAdmin ? (
    <Flex
      minH="20px"
      maxH="20px"
      minW="100%"
      bg="green.400"
      borderTopLeftRadius="8px"
      borderTopRadius="8px"
      alignItems="center"
      justifyContent="flex-end"
    >
      <Button
        h="18px"
        w="18px"
        backgroundColor="transparent"
        _hover={{
          bg: 'green.300',
        }}
        _focus={{
          boxShadow: 'none',
        }}
        marginRight="1px"
        onClick={handlePressRemovePanel}
      >
        <Icon as={VscChromeClose} color="whiteAlpha.900" />
      </Button>
    </Flex>
  ) : null;
};

export { PanelHeader };
