import { action } from 'typesafe-actions';

import { VisionModel } from '@/modules/vision/models/vision';

import { GroupModel } from '../models/group';
import { ActionTypes } from './types';

export const setCurrentGroup = (data: GroupModel) =>
  action(ActionTypes.SET_CURRENT_GROUP, { data });

export const setGroups = (data: GroupModel[]) =>
  action(ActionTypes.SET_GROUPS, { data });

export const setNewGroup = (data: GroupModel) =>
  action(ActionTypes.SET_NEW_GROUP, { data });

export const setNewVisionInCurrentGroup = (data: VisionModel) =>
  action(ActionTypes.SET_NEW_VISION, { data });

export const editGroup = (data: GroupModel) =>
  action(ActionTypes.EDIT_GROUP, { data });

export const removeGroup = (id: string) =>
  action(ActionTypes.REMOVE_GROUP, { id });

export const openModal = (typeModal: 'add' | 'edit') =>
  action(ActionTypes.OPEN_MODAL, { typeModal });

export const closeModal = () => action(ActionTypes.CLOSE_MODAL);

export const openDialog = () => action(ActionTypes.OPEN_DIALOG);

export const closeDialog = () => action(ActionTypes.CLOSE_DIALOG);
