import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { WidthProvider, Responsive, Layouts } from 'react-grid-layout';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '@/main/config/redux';
import { updateLayout } from '@/modules/vision/state/actions';

import { IframePanel } from './iframe-panel';
const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GridLayoutPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { panel, vision } = useSelector((state: RootState) => state);

  const onLayoutChange = useCallback(
    (layout: Layouts): void => {
      dispatch(updateLayout(layout, vision.currentVision.id));
    },
    [dispatch, vision.currentVision],
  );

  return vision?.currentVision?.id ? (
    <ResponsiveReactGridLayout
      style={{
        display: 'flex',
        flex: 1,
      }}
      useCSSTransforms
      layouts={vision?.currentVision?.layout || ({} as Layouts)}
      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
      compactType="vertical"
      onLayoutChange={(layout, layouts) => onLayoutChange(layouts)}
    >
      {panel.panels.map((panel) => (
        <div
          key={panel.id}
          data-grid={{
            i: panel.id,
            w: 3,
            h: 2,
            minW: 2,
            minH: 2,
            x: 0,
            y: 0,
          }}
        >
          <IframePanel
            key={panel.id}
            id={panel.id}
            url={panel.url}
            name={panel.name}
            isFull={panel.isFull}
          />
        </div>
      ))}
    </ResponsiveReactGridLayout>
  ) : null;
};

export { GridLayoutPanel };
