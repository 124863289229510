import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from '@chakra-ui/checkbox';
import { Flex, VStack } from '@chakra-ui/layout';
import { CircularProgress } from '@chakra-ui/progress';
import { useToast } from '@chakra-ui/toast';

import { RootState } from '@/main/config/redux';
import { Modal } from '@/shared/components/atoms/modal';

import { makeAssociateUserVisionUseCase } from '../../factories/make-associate-user-vison-use-case';
import { makeGetRelatedUsersByVisionIdUseCase } from '../../factories/make-get-related-users-by-vion-id-use-case';
import { makeRemoveAssociateUserVisionUseCase } from '../../factories/make-remove-associate-user-vison-use-case';
import { closeModalShare } from '../../state/actions';
import { IGetRelatedUsersByVisionId } from '../../use-cases/contracts/get-related-users-by-vision-id';

const ShareVision: React.FC = () => {
  const toast = useToast();
  const dispatch = useDispatch();

  const {
    vision: { currentVision, isOpenModalShare },
  } = useSelector((state: RootState) => state);

  const [usersVisions, setUsersVisions] =
    useState<IGetRelatedUsersByVisionId.Result>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handlePressCancel = useCallback((): void => {
    dispatch(closeModalShare());
  }, [dispatch]);

  const onChange = useCallback(
    async (active: boolean, userId: string) => {
      const visionId = currentVision.id;
      if (active) {
        const associateUserVisionUseCase = makeAssociateUserVisionUseCase();
        await associateUserVisionUseCase.execute({ userId, visionId });
      } else {
        const removeAssociateUserVisionUseCase =
          makeRemoveAssociateUserVisionUseCase(visionId, userId);

        await removeAssociateUserVisionUseCase.execute();
      }
    },
    [currentVision],
  );

  useEffect(() => {
    (async () => {
      if (isOpenModalShare) {
        setIsLoading(true);
        const getRelatedUsersByVisionIdUseCase =
          makeGetRelatedUsersByVisionIdUseCase(currentVision.id);

        try {
          const data = await getRelatedUsersByVisionIdUseCase.execute();

          if (data) {
            setUsersVisions(data);
          }
        } catch (error) {
          toast({
            title: 'Ocorreu um erro ao tentar listar os usuarios dessa visão.',
            description: (error as Error).message,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [currentVision, toast, isOpenModalShare]);

  return (
    <Modal
      title="Compartilhar visão"
      handlePressCancel={handlePressCancel}
      buttonsVisible={false}
      visible={isOpenModalShare}
    >
      {isLoading ? (
        <CircularProgress isIndeterminate size="50px" color="green.500" />
      ) : (
        <VStack alignItems="flex-start" spacing="8">
          {usersVisions?.map((userVision) => (
            <Flex key={userVision.userId} w="100%" flexDir="column">
              <Checkbox
                spacing="4"
                size="md"
                colorScheme="green"
                borderColor="black"
                defaultIsChecked={Boolean(userVision.visionId)}
                onChange={(e) => onChange(e.target.checked, userVision.userId)}
              >
                {userVision.userName}
              </Checkbox>
            </Flex>
          ))}
        </VStack>
      )}
    </Modal>
  );
};

export { ShareVision };
