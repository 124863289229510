import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { IDeleteSpace } from '../use-cases/contracts/delete-space';
import { DeleteSpaceUseCase } from '../use-cases/delete-space';

export const makeDeleteSpaceUseCase = (): IDeleteSpace =>
  new DeleteSpaceUseCase(
    makeApiUrl('/space'),
    makeAuthorizeHttpClientDecorator(),
  );
