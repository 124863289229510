import { makeApiUrl } from '@/shared/factories/make-api-url';
import { makeAuthorizeHttpClientDecorator } from '@/shared/factories/make-authorize-http-client-decorator';

import { AssociateUserVisionUseCase } from '../use-cases/associate-user-vison';
import { IAssociateUserVision } from '../use-cases/contracts/associate-user-vison';

export const makeAssociateUserVisionUseCase = (): IAssociateUserVision =>
  new AssociateUserVisionUseCase(
    makeApiUrl(`/user-vision`),
    makeAuthorizeHttpClientDecorator(),
  );
